import React from 'react'
import Layout from '../layouts/mainlayout.js'
import "../styles/global.css"

export default function index() {
  return (
    <Layout>
      <section className = "homeHero">
        <div className="homeHero_container">
          <h1>Need a Website?</h1>
          <h2>I've got what you need</h2>
        </div>
      </section>
      <section className = "homeSection2">
        <div className="homeSection2_container">
          <h1>I'm Paul</h1>
          <h3>And I Design and Develope Sites That Are</h3>
          <h1>Blazing Fast</h1>
          <h1>Professional Looking</h1>
          <h1>&</h1>
          <h1>Highly Flexible</h1>
        </div>
      </section>
      <section className = "homeSection3">
        <div className="homeSection3_container">
          <h1>Any Personal Or Business Need</h1>
          <h1>Info Page</h1>
          <h1>Blog</h1>
          <h1>Online Store</h1>
          <h1>Compex Web App</h1>
          <h1>Or Anything In Between</h1>
        </div>
      </section>
      <section className = "homeSection4">
        <div className="homeSection4_container">
          <h1>Variety Of Different Approaches</h1>
          <h1>Traditional HTML/CSS/JS</h1>
          <h1>WordPress</h1>
          <h1>React/Node</h1>
        </div>
      </section>
      <section className = "homeSection5">
        <div className="homeSection5_container">
          <h1>Fast & Affordable</h1>
          <h1>No 9 to 5</h1>
          <p></p>
          <h1>No-nonense Pricing</h1>
          <p></p>
        </div>
      </section>
      <section className = "homeSection6">
        <div className="homeSection6_container">
          <h1>Projects</h1>
          <h1>Hire Me</h1>
          <h1>Prices</h1>
        </div>
      </section>
    </Layout>
  )
}


